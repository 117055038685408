import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ContactPayload, Contacts } from '@capacitor-community/contacts'
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs'

import {
	argentinianMockNumber,
	ListUsersToChat,
  listUsersToChatFlat,
} from '@/app/mocks/data/contact-lists.mock'
import { GlobalConsts } from '@/app/shared/enums/index'
import { AppService } from './app.service'
import { AuthService } from './auth.service'
import { CryptoService } from './crypto.service'
import { isMobile } from './utils.service'

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  contactsList$: BehaviorSubject<ListUsersToChat> =
    new BehaviorSubject<ListUsersToChat>({ filtered: [], usersForInvite: [] })
  contactsListFlat$: BehaviorSubject<GetContactsRequestBody[]> =
    new BehaviorSubject<GetContactsRequestBody[]>([])

  constructor(
    private appService: AppService,
    private httpClient: HttpClient,
    private cryptoService: CryptoService,
    private authService: AuthService,
  ) {}

  get contactsList() {
    return this.contactsList$.asObservable()
  }

  async getContacts(contacts: GetContactsRequestBody[]) {
    const url = GlobalConsts.endpoints.contacts.default
    return lastValueFrom(
      this.httpClient.post(url, { contacts }).pipe(
        map(async (r) => {
          const data = r as ListUsersToChat
          const promises = data.filtered.map(async (x) => {
            if (!x.UserChat[0]?.Chat) return x
            const chat = await this.cryptoService.decryptChat(
              x.UserChat[0].Chat,
              this.authService.authData.value?.user.id!,
              x.id,
            )
            return { ...x, UserChat: [{ Chat: chat }] }
          })
          data.filtered = await Promise.all(promises)
          this.contactsList$.next(data)
          return data
        }),
        catchError((error) =>
          this.appService.handleBackendError('getContacts', error),
        ),
      ),
    )
  }

  async getWebContacts() {
    const url = GlobalConsts.endpoints.contacts.webDefault
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map(async (r) => {
          const data = r as ListUsersToChat
          const promises = data.filtered.map(async (x) => {
            if (!x.UserChat[0]?.Chat) return x
            const chat = await this.cryptoService.decryptChat(
              x.UserChat[0].Chat,
              this.authService.authData.value?.user.id!,
              x.id,
            )
            return { ...x, UserChat: [{ Chat: chat }] }
          })
          data.filtered = await Promise.all(promises)
          this.contactsList$.next(data)
          return data
        }),
        catchError((error) =>
          this.appService.handleBackendError('getContacts', error),
        ),
      ),
    )
  }

  async updateContacts(body: any): Promise<Contact[]> {
    const url = GlobalConsts.endpoints.contacts.default
    return lastValueFrom(
      this.httpClient.put(url, body).pipe(
        map((r) => {
          const data = r as Contact[]
          return data
        }),
        catchError((error: any) =>
          this.appService.handleBackendError('getContacts', error),
        ),
      ),
    )
  }

  parseContacts(
    contacts: ContactPayload[],
    ownerPhoneNumber: string,
  ): GetContactsRequestBody[] {
    return contacts
      .map((c) => {
        return {
          display: c.name?.display || '',
          phoneNumber: c.phones?.[0].number || '',
        }
      })
      .map((x) => ({
        ...x,
        phoneNumber: this.getPhoneNumberObj(
          x.phoneNumber,
          parsePhoneNumber(ownerPhoneNumber)?.country,
        ),
      }))
      .filter((x) => x.phoneNumber && x.phoneNumber.isPossible() && x.display)
      .map((x) => ({
        ...x,
        phoneNumber: x.phoneNumber?.formatInternational(),
      }))
      .map((x) => ({ ...x, phoneNumber: x.phoneNumber ?? '' }))
      .map((x) => ({ ...x, phoneNumber: this.parseArgNumber(x.phoneNumber) }))
  }

  parseArgNumber(number: string) {
    let parts: string[] = number.split(' ')
    if (parts.length == 5) {
      parts.splice(1, 1)
      return parts.join(' ')
    }

    return number
  }

  getPhoneNumberObj = (num: string, defaultCountry: CountryCode = 'AR') => {
    let parsed = parsePhoneNumber(num)
    if (!parsed) {
      parsed = parsePhoneNumber(num, defaultCountry)
    }
    return parsed
  }

  async reloadContacts() {
    const result = await Contacts.getContacts({
      projection: {
        name: true,
        phones: true,
      },
    })

    const parsedContacts: any[] = this.parseContacts(
      result.contacts,
      argentinianMockNumber,
    )
    this.contactsList$.next({
      filtered: parsedContacts,
      usersForInvite: [],
    })
  }

  async getLoadedContacts() {
    const contacts = this.contactsListFlat$.getValue()
    if (contacts.length > 0) return contacts;

    if (!isMobile()) return listUsersToChatFlat;

    const result = await Contacts.getContacts({
      projection: {
        name: true,
        phones: true,
      },
    })

    const parsedContacts = this.parseContacts(
      result.contacts,
      argentinianMockNumber,
    )
    this.contactsListFlat$.next(parsedContacts)
    return parsedContacts
  }
}
