import { Component } from '@angular/core'
import { Platform } from '@ionic/angular'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { OnesignalNotificationService } from '../core/services/onesignal-notification.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly oneSignalNotificationService: OnesignalNotificationService,
    private readonly platform: Platform,
  ) {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile')) {
        this.oneSignalNotificationService.bindNotifications()
      } else {
        defineCustomElements(window);
      }
    })
  }

  async ngOnInit() {
    const packageJson = await import('../../package.json')
    console.info(`Version: Web-v${packageJson.version}`)
  }
}
