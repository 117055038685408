<div
    [formGroup]="formGroup"
    class="flex flex-col items-start justify-between gap-y-0.5 w-full"
    [ngClass]="{
        'pt-3': !label,
    }"
>
    @if (label) {
        <label class="text-right text-white font-regular w-max ml-[3px]">{{
            label
        }}</label>
    }
    <ng-content [select]="begin"></ng-content>
    <input
        class="w-full h-[45px] text-white bg-input-bg placeholder:text-gray px-2.5 rounded-xs"
        (change)="onChange($event)"
        [type]="inputType"
        [placeholder]="placeholder"
        [formControlName]="controlName"
        [ngStyle]="{ height: h > 0 ? h + 'px' : 'auto' }"
    />
    <ng-content [select]="end"></ng-content>
</div>
