import { Injectable } from '@angular/core'
import { Socket } from 'ngx-socket-io'
import { BehaviorSubject, Observable } from 'rxjs'

import { environment } from '@/environments/environment'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket!: Socket
  isConnected = new BehaviorSubject<boolean>(false)
  isConnecting = new BehaviorSubject<boolean>(false)

  constructor(private readonly authService: AuthService) {
    this.authService.authData.subscribe((authData) => {
      if (!authData) this.disconnect()
      else this.connect(authData.token)
    })
  }

  listen<T>(eventName: string) {
    return new Observable<T>((subscriber) => {
      this.socket.on(eventName, (data: T) => {
        // console.log(eventName, data);
        subscriber.next(data)
      })
    })
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data)
  }

  connect(token: string) {
    if (!this.isConnecting.value) {
      this.isConnecting.next(true)
      if (this.isConnected.value) {
        this.isConnecting.next(false)
        return
      }

      this.socket = new Socket({
        url: environment.webSocketUrl,
        options: {
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: token,
              },
            },
          },
        },
      })

      return new Promise<void>((res) => {
        this.socket.on('connection-success', () => {
          this.isConnected.next(true)
          this.isConnecting.next(false)
          res()
        })

        this.socket.on('connection-error', () => {
          this.isConnected.next(false)
          this.isConnecting.next(false)
          res()
        })
      })
    } else {
      return new Promise<void>((res) => {
        this.isConnecting.subscribe((val) => {
          if (!val) res()
        })
      })
    }
  }

  disconnect() {
    this.isConnected.next(false)
    if (this.socket) this.socket.disconnect()
  }
}
