export const endpoints = {
  log: 'auth/log',
  auth: {
    default: 'auth',
    register: 'auth/register',
    login: 'auth/login',
    me: 'auth/me',
    logout: 'auth/logout',
  },
  users: {
    default: 'users',
  },
  contacts: {
    default: 'contacts',
    webDefault: 'contacts/getWebContacts',
  },
  chats: {
    default: 'chats',
    send: 'chats/send-message',
    chat: (targetId: string) => `chats/${targetId}`,
    id: (chatId: string) => `chats/${chatId}/id`,
    force: (targetId: string) => `chats/${targetId}/force`,
    historial: 'chats/historial',
    save: 'chats/save',
    search: 'chats/search',
    delete: 'chats/delete',
    getOrCreateChat: (userToId: string) =>
      `chats/get-or-create-chat/${userToId}`,
  },
  calls: {
    default: 'calls',
    missedCalls: 'calls/check-missed-calls',
    callByUser: (callId: string) => `calls/${callId}/user`,
    call: (callId: string) => `calls/${callId}`,
  },
}
