import { endpoints } from './endpoints'
import { message } from './messages'
import { regEx } from './regexp'
import { routes } from './routes'
import { storage } from './storage-keys'

export const GlobalConsts = {
  endpoints,
  storage,
  message,
  regEx,
  routes,
} as const
