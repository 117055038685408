import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { Router } from '@angular/router'
import { type Subscription } from 'rxjs'

import { WebSocketService } from '@core/services/websocket.service'

@Component({
  selector: 'app-incomming-call',
  templateUrl: './incomming-call.component.html',
  styleUrls: ['./incomming-call.component.scss'],
})
export class IncommingCallComponent implements OnInit {
  @Input() incomingCall!: SocketReceiveCall
  @Output('reject') onRejectEvent = new EventEmitter<void>()
  @Output('accept') onAcceptEvent = new EventEmitter<void>()

  private ringtone!: HTMLAudioElement
  private readonly subscriptions: Subscription[] = []

  constructor(
    private readonly webSocketService: WebSocketService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.ringtone = new Audio('assets/ringtones/ringtone.mp3')
    this.ringtone.loop = true
    this.ringtone.volume = 0.25
    this.ringtone.play()

    const sub1 = this.webSocketService
      .listen(`call-deleted/${this.incomingCall?.callId}`)
      .subscribe(() => {
        this.ringtone?.pause?.()
        this.router.navigate(['/home'], { replaceUrl: true })
      })

    this.subscriptions.push(sub1)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  public onAccept() {
    this.ringtone.pause()

    this.webSocketService.emit('accept-call', {
      callId: this.incomingCall?.callId,
    })
    this.router.navigate(['/meeting'], {
      replaceUrl: true,
      queryParams: {
        remoteUserName:
          this.incomingCall?.members[0].username ||
          this.incomingCall?.members[0].phoneNumber,
        callId: this.incomingCall?.callId,
      },
    })
    this.onAcceptEvent.emit()
  }

  public onReject() {
    this.webSocketService.emit('reject-call', {
      callId: this.incomingCall?.callId,
    })
    this.ringtone.pause()
    this.onRejectEvent.emit()
  }

  public onOpen() {
    // TO-DO...
  }

  public onBusy() {
    // TO-DO...
  }

  public onImgError(ev: Event) {
    ;(ev.target as HTMLImageElement).src =
      'https://ionicframework.com/docs/img/demos/avatar.svg'
  }
}
