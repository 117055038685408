<main
    class="lg:flex lg:items-center lg:justify-center w-screen min-h-[100dvh] bg-none absolute top-0 left-0"
>
    <section
        class="textured-background relative w-full min-h-[100dvh] z-50 bg-[url(/assets/textures/MeshBackground.sm.png)] bg-cover bg-no-repeat lg:rounded-lg lg:min-h-[395px] lg:w-[312px] lg:bg-none lg:bg-searchbar-bg"
    >
        <i
            class="text-xs text-white text-center font-normal w-full not-italic block pt-6 lg:text-start lg:pl-4 lg:mt-[-5px] lg:text-gray"
            >Llamada entrante</i
        >

        <div
            class="flex flex-col items-center justify-center mt-[65px] lg:hidden"
        >
            <strong class="text-white font-black text-2xl">{{
                incomingCall?.members?.[0]?.username || ''
            }}</strong>
            <p class="text-white text-xs font-normal">
                {{ incomingCall?.members?.[0]?.phoneNumber || '' }}
            </p>
        </div>

        <img
            height="140"
            width="140"
            [src]="incomingCall?.members?.[0]?.avatarUrl"
            (error)="onImgError($event)"
            class="rounded-[100%] min-h-[140px] min-w-[140px] max-h-[140px] max-w-[140px] ml-auto mr-auto mt-[85px] lg:mt-[28px]"
        />

        <div
            class="flex-col items-center justify-center mt-[65px] hidden lg:mt-3 lg:flex"
        >
            <strong class="text-white font-black text-2xl">{{
                incomingCall?.members?.[0]?.username || ''
            }}</strong>
            <p class="text-white text-xs font-normal">
                {{ incomingCall?.members?.[0]?.phoneNumber || '' }}
            </p>
        </div>

        <section
            class="flex flex-col w-full items-center justify-between absolute bottom-[64px] px-[72px] gap-y-[60px] lg:px-4 lg:bottom-[15px]"
        >
            <div
                class="flex flex-row w-full items-center justify-between lg:hidden"
            >
                <div
                    class="ion-activatable ripple-parent relative flex flex-col items-center justify-center w-[72px] cursor-pointer"
                    (click)="onOpen()"
                >
                    <ion-icon
                        class="text-[25px] text-white"
                        name="arrow-redo"
                    ></ion-icon>
                    <p class="text-white text-sm font-normal">Ir al chat</p>
                    <ion-ripple-effect></ion-ripple-effect>
                </div>

                <div
                    class="ion-activatable ripple-parent relative flex flex-col items-center justify-center w-[72px] cursor-pointer"
                    (click)="onBusy()"
                >
                    <ion-icon
                        class="text-[25px] text-white"
                        name="chatbubble"
                    ></ion-icon>
                    <p class="text-white text-sm font-normal whitespace-nowrap">
                        Estoy ocupado
                    </p>
                    <ion-ripple-effect></ion-ripple-effect>
                </div>
            </div>

            <div
                class="flex flex-row w-full items-center justify-between lg:gap-x-2.5 cursor-pointer"
            >
                <div
                    (click)="onReject()"
                    class="ion-activatable ripple-parent relative flex flex-col items-center justify-center overflow-hidden gap-y-1 lg:w-6/12 lg:justify-between lg:flex-row lg:bg-red lg:rounded-[10px] lg:gap-x-2 lg:py-1 lg:px-6"
                >
                    <button
                        class="bg-red grid place-items-center w-[72px] h-[72px] rounded-[100%] lg:h-[38px] lg:w-max"
                    >
                        <ion-icon
                            class="text-[22px] rotate-[135deg]"
                            name="call"
                        ></ion-icon>
                    </button>
                    <p class="font-normal text-xs text-white">Colgar</p>
                    <ion-ripple-effect></ion-ripple-effect>
                </div>

                <div
                    (click)="onAccept()"
                    class="ion-activatable ripple-parent overflow-hidden relative flex flex-col items-center justify-center gap-y-1 lg:w-6/12 lg:justify-between lg:flex-row lg:bg-green lg:rounded-[10px] lg:gap-x-2 lg:py-1 lg:px-6 cursor-pointer"
                >
                    <button
                        class="bg-green grid place-items-center w-[72px] h-[72px] rounded-[100%] lg:h-[38px] lg:w-max"
                    >
                        <ion-icon class="text-[22px]" name="call"></ion-icon>
                    </button>
                    <p class="font-normal text-xs text-white">Aceptar</p>
                    <ion-ripple-effect></ion-ripple-effect>
                </div>
            </div>
        </section>
    </section>
</main>
