import { Component, Input, OnInit } from '@angular/core'
import { ControlService } from '@core/services/control.service'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isLoading = false
  @Input() forced = false;

  constructor(private readonly controlService: ControlService) { }

  ngOnInit() {
    this.controlService.loading$.subscribe((val) => { this.isLoading = val })
  }
}
