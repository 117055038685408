import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { Storage } from '@ionic/storage-angular'

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private isWeb: boolean = false
  private _storage: Storage | null = null

  constructor(
    private platform: Platform,
    private storage: Storage,
  ) {
    this.init()
    this.isWeb = !this.platform.is('capacitor')
  }

  async init(): Promise<void> {
    if (!this._storage) {
      const storage = await this.storage.create()
      this._storage = storage
    }
  }

  async findOne<T extends unknown>(key: string): Promise<T | null> {
    if (this.isWeb) {
      const verboseValue = localStorage.getItem(key)
      return verboseValue ? JSON.parse(verboseValue) : null
    } else {
      return await this.storage.get(key)
    }
  }

  async insert(key: string, value: any) {
    if (this.isWeb) {
      await Promise.resolve(localStorage.setItem(key, value))
    } else {
      await this.storage.set(key, value)
    }
  }

  async bulkInsert(data: { key: string; value: any }[]) {
    const promises = data.map(({ key: k, value: v }) => {
      if (this.isWeb) {
        return Promise.resolve(localStorage.setItem(k, v))
      } else {
        return this.storage.set(k, v)
      }
    })

    await Promise.all(promises)
  }

  async bulkDelete(keys: string[]) {
    const promises = keys.map((key: string) => {
      if (this.isWeb) {
        return Promise.resolve(localStorage.removeItem(key))
      } else {
        return this.storage.remove(key)
      }
    })

    await Promise.all(promises)
  }

  async delete(key: string) {
    if (this.isWeb) {
      await Promise.resolve(localStorage.removeItem(key))
    } else {
      await this.storage.remove(key)
    }
  }
}
