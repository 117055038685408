export const argentinianMockNumber = '11 4403 4356'

const mockedUsersForChat: User[] = [
  {
    id: '1',
    avatarUrl: '',
    email: '',
    phoneNumber: '11',
    username: 'branodn mock, user app installd ningun message',
  },
  {
    id: '2',
    avatarUrl: '',
    email: '',
    phoneNumber: '22',
    username: 'lean mock, user app installd ningun message',
  },
]

export type ListUsersToChat = {
  filtered: User[]
  usersForInvite: GetContactsRequestBody[]
}

export const listUsersToChatFlat: GetContactsRequestBody[] = [
  { display: 'mario', phoneNumber: '+54 11 6020 6561' },
  { display: 'Simon 1', phoneNumber: '+54 11 1234 1234' },
  { display: 'Simon 2', phoneNumber: '+54 11 2475 0724' },
  { display: 'otro', phoneNumber: '+54 11 2233 4455' },
  { display: 'otro 2', phoneNumber: '+54 11 2222 2222' },
  { display: 'brandon', phoneNumber: '+54 11 4403 4356' },
  { display: 'leandro', phoneNumber: '+54 11 3580 3252' },
  { display: 'mauro', phoneNumber: '+54 11 3139 8390' },
  { display: 'cc2 c2', phoneNumber: '+54 11 2200 0220' },
  { display: 'cc1 c1', phoneNumber: '+54 11 1101 0101' },
  { display: 'sofia usa', phoneNumber: '+1 720 584 6934' },
]

export const listUsersToChat: ListUsersToChat = {
  filtered: [...mockedUsersForChat],
  usersForInvite: [
    {
      phoneNumber: '33',
      display: 'for invite mario dock',
    },
  ],
}
