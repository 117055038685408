import { inject } from '@angular/core'
import { Router } from '@angular/router'

import { GlobalConsts } from '@/app/shared/enums'
import { AuthService } from '@core/services/auth.service'

export const AutoLoginGuard = async () => {
  const authenticationService = inject(AuthService)
  const router = inject(Router)

  await authenticationService.loadAuthData()
  const authData = authenticationService.getUser();

  if (authData) {
    return true
  } else {
    router.navigateByUrl(GlobalConsts.routes.auth.default, { replaceUrl: true })
    return false
  }
}
