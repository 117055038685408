import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map } from 'rxjs';
import { AppService } from './app.service';
import { GlobalConsts } from '@/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(private httpClient: HttpClient, private appService: AppService) {}

  getUserCallbyCallId(callId: string): Promise<UserCall> {
    return lastValueFrom(
      this.httpClient.get(GlobalConsts.endpoints.calls.callByUser(callId)).pipe(
        map((r) => r as UserCall),
        catchError((error: any) =>
          this.appService.handleBackendError('getUserCallbyCallId', error)
        )
      )
    );
  }

  getCallById(callId: string): Promise<Call> {
    return lastValueFrom(
      this.httpClient.get(GlobalConsts.endpoints.calls.call(callId)).pipe(
        map((r) => r as Call),
        catchError((error: any) =>
          this.appService.handleBackendError('getCallById', error)
        )
      )
    );
  }
}
