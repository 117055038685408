import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ReceiverService {
  private receiverSubject = new BehaviorSubject<User | null>(null)
  private activeChatSubject = new BehaviorSubject<Chat | null>(null)

  receiver$ = this.receiverSubject.asObservable()
  activeChat$ = this.activeChatSubject.asObservable()

  getReceiver() {
    return this.receiverSubject.getValue()
  }

  selectReceiver(user: User) {
    this.receiverSubject.next(user)
  }

  unselectReceiver() {
    this.receiverSubject.next(null)
  }

  setActiveChat(chat: Chat) {
    this.activeChatSubject.next(chat);
  }

  unselectActiveChat() {
    this.activeChatSubject.next(null)
  }

  getActiveChat() {
    return this.activeChatSubject.getValue()
  }
}
