import { Injectable } from '@angular/core'
import { compareDesc } from 'date-fns'
import { BehaviorSubject } from 'rxjs'

import { GlobalConsts } from '@/app/shared/enums'
import { environment } from '@/environments/environment'
import { ControlService } from './control.service'
import { StorageService } from './storage.service'
import { getMimeTypeByExtension } from './utils.service'

var KBPlugin: any

@Injectable({ providedIn: 'root' })
export class CryptoService {
  loadingPromise: Promise<void>
  mode = new BehaviorSubject<'endpoint' | 'keybox'>('endpoint')
  isConnected = new BehaviorSubject<Boolean>(false)

  constructor(
    private readonly controlService: ControlService,
    private readonly storageService: StorageService,
  ) {
    this.loadingPromise = this.inicializeCrypto()
  }

  async inicializeCrypto() {
    try {
      const currentMode = await this.storageService.findOne(
        GlobalConsts.storage.keyMode,
      )
      console.log(currentMode)
      this.mode.next((currentMode ?? 'endpoint') as 'endpoint' | 'keybox')
      if (currentMode === 'keybox') {
        require('capacitor-kbplugin')
      }
    } catch (error) {
      this.mode.next('endpoint')
      console.log(error)
    }

    this.mode.subscribe((mode) => {
      this.storageService.insert(GlobalConsts.storage.keyMode, mode)
    })

    let live: NodeJS.Timeout
    this.mode.subscribe((mode) => {
      if (mode === 'keybox') {
        this.isConnected.next(true)
        live = setInterval(async () => {
          const resp = await this.generateKey({
            SEED: '1',
            SESSION: '1',
            KEYS: 1,
            BITS: 1,
            showError: false,
          })
          if (resp.status !== 'OK') {
            this.mode.next('endpoint')
            this.isConnected.next(false)
          }
        }, 2000)
      } else {
        clearInterval(live)
      }
    })
  }

  async generateKey(payload: {
    SEED: string
    SESSION: string
    KEYS: number
    BITS: number
    start?: number
    showError?: boolean
  }) {
    if (this.mode.getValue() === 'endpoint') {
      return fetch(
        'https://soothing-enjoyment-development.up.railway.app/api/crypto',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        },
      )
        .then(function (res) {
          return res.json()
        })
        .then(function (res) {
          return {
            keys: res.keys as number[],
            end: res.end,
            status: 'OK',
          }
        })
    } else {
      let response
      if (payload.start) {
        console.log({
          keys: payload.KEYS,
          bits: payload.BITS,
          start: payload.start,
        })
        response = await KBPlugin.continueKeys({
          keys: payload.KEYS,
          bits: payload.BITS,
          start: payload.start,
        }).then((x: any) => {
          console.log(x)
          return x
        })
      } else {
        const seed = payload.SEED.split('')
          .map((x) => x.charCodeAt(0))
          .reduce((x, y) => x + y)
        const session = payload.SESSION.split('')
          .map((x) => x.charCodeAt(0))
          .reduce((x, y) => x + y)
        response = await KBPlugin.newKeys({
          keys: payload.KEYS,
          bits: payload.BITS,
          session: session,
          seed: seed,
        })
      }
      if (
        response.status !== 'OK' &&
        (payload.showError === undefined || payload.showError)
      ) {
        this.controlService.toast.show({ message: `error: ${response.status}` })
      }
      return response
    }
  }

  async decryptChat(
    chat: Chat,
    from: string,
    to: string,
    prev?: Chat,
  ): Promise<Chat> {
    let messages = chat.Message ?? []
    const keysInfo: KeysInfo = {}
    keysInfo[from] = {
      keys: [],
      end: 0,
      ...prev?.keysInfo[from],
    }

    keysInfo[to] = {
      keys: [],
      end: 0,
      ...prev?.keysInfo[to],
    }

    for (let i = 0; i < Object.keys(keysInfo).length; i++) {
      const index = Object.keys(keysInfo)[i]
      const length = this.getKeysLength(messages, index) + 5000
      if (!prev || length > prev.keysInfo[index].keys.length) {
        const resp = await this.generateKey({
          SEED: chat.id,
          SESSION: index,
          KEYS: length - (prev?.keysInfo[index].keys.length ?? 0),
          BITS: 8,
          start: prev?.keysInfo[index].end ?? 0,
        })
        keysInfo[index] = {
          keys: keysInfo[index].keys.concat(resp.keys),
          end: resp.end,
        }
      }
    }

    messages = await Promise.all(
      messages.map(async (x) => ({
        ...x,
        content: this.decryptMessageContent(x, keysInfo[x.senderId!].keys),
        filename: this.decryptMessageFilename(x, keysInfo[x.senderId!].keys),
        file: await this.decryptMessageFile(x, keysInfo[x.senderId!].keys),
      })),
    )

    return {
      ...chat,
      Message: messages,
      keysInfo,
    }
  }

  getCurrentLengthOfMessage(
    message: Message,
    messagesOfChat: Message[],
    userId: string,
  ): number {
    return (
      this.getKeysLength(messagesOfChat, userId) +
      (message.content?.length ?? 0) +
      (message.filename?.length ?? 0) +
      (message.file?.size ?? 0)
    )
  }

  getKeysLength(messages: Message[], userId: string) {
    const sorted = [...messages].sort((x, y) =>
      compareDesc(x.createdAt, y.createdAt),
    )
    return sorted.find((x) => x.senderId === userId)?.currentLength ?? 0
  }

  // DECRYPT METHODS
  decryptMessageContent(message: Message, keys: number[]) {
    if (!message.content) return ''

    let i = message.currentLength
    if (message.content) i -= message.content.split(' ').length

    return message.content
      .split(' ')
      .map((y, j) => {
        return String.fromCharCode(+y ^ keys[i + j])
      })
      .join('')
  }

  decryptMessageFilename(message: Message, keys: number[]) {
    if (!message.filename) return ''

    let i = message.currentLength
    if (message.content) i -= message.content.split(' ').length
    if (message.filename) i -= message.filename.split(' ').length

    const resp = message
      .filename!.split(' ')
      .map((y, j) => {
        return String.fromCharCode(+y ^ keys[i + j])
      })
      .join('')

    return resp
  }

  async decryptMessageFile(message: Message, keys: number[]) {
    if (message.PartsOfFile?.length == 0) return
    if (!message.PartsOfFile) return

    const urlPathsOfPartsOfFile = message.PartsOfFile.sort(
      (x, y) => x.chunkIndex - y.chunkIndex,
    ).map((part: PartOfFile) => `${environment.baseURL}${part.urlPath}`)
    const mimeType = getMimeTypeByExtension(urlPathsOfPartsOfFile.at(0)!)

    const blobsOfPartsOfFile = await Promise.all(
      urlPathsOfPartsOfFile.map(
        async (path: string) =>
          await fetch(path).then((result: any) => result.blob()),
      ),
    )

    const sizeOfFiles = blobsOfPartsOfFile
      .flatMap((blob: Blob) => blob.size)
      .reduce((accumulator, currentValue) => accumulator + currentValue)

    let i = message.currentLength
    if (message.PartsOfFile.length > 0) i -= sizeOfFiles
    const desencryptedUi8Promise = blobsOfPartsOfFile.map(
      async (partOfFileToDecrypt: Blob) => {
        const raw = new Uint8Array(await partOfFileToDecrypt.arrayBuffer())
        return raw.map((y, j) => y ^ keys[i + j])
      },
    )

    const desencryptedUi8 = await Promise.all(desencryptedUi8Promise!)
    return new Blob(desencryptedUi8.flat(), { type: mimeType })
  }

  // ENCRYPT METHODS
  encryptMessageContent(message: Message, keys: number[]) {
    if (!message.content) return ''

    let i = message.currentLength
    if (message.content) i -= message.content.split('').length

    return message.content
      .split('')
      .map((y, j) => {
        return y.charCodeAt(0) ^ keys[i + j]
      })
      .join(' ')
  }

  encryptMessageFilename(message: Message, keys: number[]) {
    if (!message.filename) return ''

    let i = message.currentLength
    if (message.content) i -= message.content.split('').length
    if (message.filename) i -= message.filename.split('').length

    return message.filename
      .split('')
      .map((y, j) => {
        return y.charCodeAt(0) ^ keys[i + j]
      })
      .join(' ')
  }

  async encryptMessageFile(message: Message, keys: number[]) {
    if (!message.file) return
    const rawFile = message.file

    let i = message.currentLength
    if (message.file) i -= rawFile.size
    const raw = new Uint8Array(await rawFile.arrayBuffer())
    const resp = raw.map((y, j) => y ^ keys[i + j])
    return (message.file = new Blob([resp], { type: rawFile.type }))
  }
}
