import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Platform } from '@ionic/angular'
import OneSignal, { OSNotification } from 'onesignal-cordova-plugin'

import { GlobalConsts } from '@/app/shared/enums'
import { environment } from '@/environments/environment'
import { AuthService } from './auth.service'
import { CallService } from './call.service'
import { ChatsService } from './chats.service'
import { ContactsService } from './contacts.service'
import { ControlService } from './control.service'
import { isMobile, UtilsService } from './utils.service'
import { listUsersToChatFlat } from '@/app/mocks/data/contact-lists.mock'

@Injectable({
  providedIn: 'root',
})
export class OnesignalNotificationService {
  constructor(
    private readonly controlService: ControlService,
    private readonly chatsService: ChatsService,
    private readonly authService: AuthService,
    private readonly utilsService: UtilsService,
    private readonly callService: CallService,
    private readonly contactsService: ContactsService,
    private readonly router: Router,
    private readonly platform: Platform,
  ) {}
  bindNotifications(): void {
    OneSignal.initialize(environment.oneSignalAppId)
    OneSignal.Notifications.requestPermission(true)

    OneSignal.Notifications.addEventListener('click', (res) => {
      const { notification } = res
      this.utilsService.log('notif: ' + JSON.stringify(notification))
      this.handleNotificationClick(notification)
    })
  }

  async handleNotificationClick(notification: OSNotification) {
    try {
      const additionalData = notification.additionalData as any
      if (!additionalData) return
      if (additionalData.chatId) {
        await this.handleMessage(additionalData)
      } else if (additionalData.callId) {
        console.log({ callId: additionalData.callId })
        await this.handleCall(additionalData)
      }
    } catch (error) {
      this.controlService.toast.show({
        message: 'Hubo un error abriendo el chat o la llamada',
      })
    }
  }

  async handleCall(additionalData: any) {
    if (this.controlService.isModalOpen) {
      return
    }

    await this.authService.loadAuthData()
    let loadedContacts = this.contactsService.contactsListFlat$.getValue()
    if (!isMobile()) loadedContacts = listUsersToChatFlat
    additionalData.members = [
      { name: additionalData.name, phoneNumber: additionalData.phoneNumber },
    ]
    const contact = loadedContacts.find(
      (c) => c.phoneNumber === additionalData.members[0].phoneNumber,
    )

    additionalData.members[0].username = contact ? contact.display : ''

    // const modal: IModal = {
    //   component: IncomingCallPage,
    //   componentProps: { data: additionalData },
    // }
    if (!this.controlService.isModalOpen) {
      console.log('callId', additionalData)
      const callData = await this.callService.getUserCallbyCallId(
        additionalData.callId,
      )
      console.log('callData')
      const callIsActive = callData.isActive
      if (callIsActive) {
        this.controlService.isModalOpen = true
        // this.controlService.modal.show(modal)
      } else {
        this.router.navigate([GlobalConsts.routes.home.default], {
          replaceUrl: true,
          queryParams: {
            isAnInactiveCall: true,
          },
        })
      }
    }
  }

  async handleMessage(additionalData: any) {
    await this.authService.loadAuthData()
    await this.chatsService.refeshChats()
    let chats = this.chatsService.chats$.getValue()

    const chat = chats.find((c) => additionalData.chatId === c.id)
    if (!chat) return
    // const modal: IModal = {
    //   component: DirectChatPage,
    //   componentProps: { to: chat.UserChat[0].User, chat },
    // }
    // this.controlService.showModal(modal).then(() => {
    //   this.chatsService.callToReloadChats()
    // })
  }

  async getUserOneSignalId(): Promise<any> {
    if (!this.platform.is('mobile')) return undefined

    const OSSubscriptionId = OneSignal.User.pushSubscription.id
    const APP_ID = environment.oneSignalAppId

    const options = {
      method: 'GET',
      headers: { accept: 'application/json' },
    }

    const response = fetch(
      `https://onesignal.com/api/v1/apps/${APP_ID}/subscriptions/${OSSubscriptionId}/user/identity`,
      options,
    )
      .then((response) => response.json())
      .then((response) => response.identity.onesignal_id)
      .catch((err) => console.error(err))

    return await response
  }
}
